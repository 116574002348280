<template>
  <div>
    <div class="banner">
      <swiper v-if="bannerData.contents" class="swiper1" ref="mySwiper1" :options="swiperOption1">
        <swiper-slide class="item-wrap" v-for="bitem in bannerData.contents" :key="bitem.id">
          <div class="banner-item"> 
            <video v-if="bitem.videoUrl" :poster="imgurl(bitem.imageUrl)" autoplay=""  playsinline="" muted="" loop="">
              <source :src="imgurl(bitem.videoUrl)" type="video/mp4">
            </video>
            <img v-else :src="imgurl(bitem.imageUrl)">
            <h2>{{bitem.title}}</h2>
            <h3>{{bitem.subTitle}}</h3>
            <div class="button" v-if="bitem.button.length" @click="openPage(bitem.url)">{{bitem.button}}</div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination swiper-pagination-bullets" slot="pagination"></div>
      </swiper>
      <img src="@/assets/homepage/banner-bottom.png" style="width: 100%;position: absolute;bottom: 0;z-index:99;">
    </div>
    <div class="content prod">
      <div class="title">
        <h2>产品与服务</h2>
        <h3>PRODUCTS AND SERVICES</h3>
      </div>
      <div class="con-prod">
        <div class="prod-item" v-for="(item, index) in productData.contents" :class="{'act': prodcurrent==index}" :key="item.id" @mouseenter="prodenter(index)">
          <img :src="imgurl(item.imageUrl)" class="nimg">
          <img :src="imgurl(item.actImageUrl)" class="himg">
          <div class="text">
            <h3>{{item.title}}</h3>
            <h4>({{item.subTitle}})</h4>
            <div class="no">0{{index+1}}</div>
            <div class="tip"></div>
            <div class="body">{{item.body}}</div>
            <i class="arrow iconfont icon-guanwangicon_jiantou" @click="topage('product', {id: item.url})"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="content scene" style="position: relative;">
      <!-- 大规模定制交互点组件 -->
      <custom-point></custom-point>
      <div class="con-scene">
        <video poster="@/assets/homepage/solutions-img.jpg" autoplay=""  playsinline="" muted="" loop="">
          <source src="@/assets/homepage/1208-3.mp4" type="video/mp4">
        </video>
      </div>
      <div ref="fadeIn1">
        <div class="title">
          <h2>场景化产品与方案</h2>
          <h3>PRODUCTS AND SOLUTIONS</h3>
        </div>
      </div>
    </div>
    <div class="content solution">
      <div class="con-solution" ref="fadeIn2">
        <div class="title">
          <h2>解决方案</h2>
          <h3>TOTAL SOLUTION</h3>
        </div>
        <swiper v-if="solutionData.contents" class="swiper2" ref="mySwiper2" :options="swiperOption2">
          <swiper-slide class="item-wrap" v-for="item in solutionData.contents" :key="item.id">
            <div class="solution-item"> 
              <img :src="imgurl(item.imageUrl)">
              <h2>{{item.title}}</h2>
              <div class="bar"></div>
              <p v-html="item.body.replace('\n','<br />')"></p>
              <div class="tips">
                <div class="tip" v-for="tip in item.summary.split(',')" :key="tip">{{tip}}</div>
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-pagination swiper-pagination-bullets" slot="pagination"></div>
        </swiper>
        <div v-show="solutionData.contents && solutionData.contents.length > 2" class="solution-pre-btn pre-btn arrow-btn"><a-icon type="left" /></div>
        <div v-show="solutionData.contents && solutionData.contents.length > 2" class="solution-next-btn next-btn arrow-btn"><a-icon type="right" /></div>
      </div>
    </div> 
    <div class="content info">
      <div ref="fadeIn3">
        <div class="title">
          <h2>热点资讯</h2>
          <h3>HOT INFO</h3>
        </div>
        <div class="con-info" v-if="infoData.contents">
          <div class="info-left">
            <img :src="imgurl(infoData.contents[currentInfo].imageUrl)">
            <div class="date">
              <div class="day">{{daycom(infoData.contents[currentInfo].date)}}</div>
              <div class="mon">{{moncom(infoData.contents[currentInfo].date)}}</div>
            </div>
            <h3>{{infoData.contents[currentInfo].title}}</h3>
            <p>{{infoData.contents[currentInfo].body}}</p>
            <div class="button" v-if="infoData.contents[currentInfo].url.length" @click="openPage(infoData.contents[currentInfo].url)">
              了解详情
              <i class="arrow iconfont icon-guanwangicon_jiantou"></i>
            </div>
          </div>
          <div class="info-right">
            <template v-for="(item, index) in infoData.contents">
              <div v-if="index!=currentInfo" :key="item.id" @click="infoClick(index)" class="info-item">
                <div class="date">
                  <div class="day">{{daycom(item.date)}}</div>
                  <div class="mon">{{moncom(item.date)}}</div>
                </div>
                <h3>{{item.title}}</h3>
                <p>{{item.body}}</p>
              </div>
            </template>
          </div>
        </div>
        <div class="button" @click="topage('news')">查看更多</div>
      </div>
    </div>
    <div class="content news">
      <div ref="fadeIn4" class="fadeIn4">
        <div class="title">
          <h2>行业动态</h2>
          <h3>INDUSTRY NEWS</h3>
        </div>
        <div class="con-news" v-if="newsData.contents">
          <div class="bar"></div>
          <div class="lbar"></div>
          <div class="rbar"></div>
          <div class="news">
            <div class="news-item" v-for="(item, index) in newsData.contents" :class="{'act': newsact==index}" @mouseenter="newsenter(index)" :key="item.id">
              <div class="date">{{item.date}}</div>
              <div class="title-bar"></div>
              <h3>{{item.title}}</h3>
              <p>{{item.body}}</p>
            </div>
          </div>
        </div>
        <div class="button" @click="topage('information')">查看更多</div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
const { VUE_APP_API } = process.env 
import CustomPoint from '@/components/CustomPoint'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import { isElementNotInViewport } from '@/utils/utils'
export default {
  components: {
    swiper,
    swiperSlide,
    CustomPoint
  },
  data() {
    return {
      bannerData: {},
      swiperOption1: {
        autoplay: {
          delay: 10000,
          disableOnInteraction: false
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          renderBullet(index, className) {
            return `<span class="${className} swiper-pagination-bullet-custom"></span>`
          }
        },
        loop: true,
      },
      swiperOption2: {
        autoplay: {
          delay: 10000,
          disableOnInteraction: false
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          renderBullet(index, className) {
            return `<span class="${className} swiper-pagination-bullet-custom"></span>`
          }
        },
        navigation: { // 左右切换
          nextEl: '.solution-next-btn',
          prevEl: '.solution-pre-btn',
        },
        loop: true,
      },
      fadeInElements: [],
      productData: {},
      solutionData: {},
      infoData: {},
      currentInfo: 0,
      newsData: {},
      prodcurrent: 0,
      newsact: 1
    }
  },
  created() {
    this.init()
  },
  mounted() {
    this.$nextTick(() => {
      this.fadeInElements = [
        this.$refs['fadeIn1'],
        this.$refs['fadeIn2'],
        this.$refs['fadeIn3'],
        this.$refs['fadeIn4']
      ]
      window.addEventListener('scroll', this.handleScroll)
    })
  },
  destroyed() {
    document.removeEventListener('scroll', this.handleScroll)
  },
  computed: {
  },
  methods: {
    topage(path,query) {
      // if(query) {
      //   window.open(window.location.href.split('#/')[0]+`#/${path}?id=${query.id}`)
      // } else {
      //   window.open(window.location.href.split('#/')[0]+`#/${path}`)
      // }
      this.$router.push({
        path: `/${path}`,
        query
      })
      scrollToTop()
    },
    imgurl(path) {
      return `${VUE_APP_API}${path}`.replace('/api', '')
    },
    daycom(date) {
      return date.split('-').pop()
    },
    moncom(date) {
      date = date.split('-')
      date.pop()
      return date.join('-')
    },
    async init() {
      this.bannerData = await api.getContentsList({
        channelId: 54
      })
      this.productData = await api.getContentsList({
        channelId: 49
      })
      this.solutionData = await api.getContentsList({
        channelId: 51
      })
      this.infoData = await api.getContentsList({
        channelId: 52
      })
      this.newsData = await api.getContentsList({
        channelId: 53
      })
    },
    prodenter(index) {
      // console.log('index: ', index);
      this.prodcurrent = index
    },
    newsenter(index) {
      this.newsact = index
    },
    handleScroll(e) {
      for (var i = 0; i < this.fadeInElements.length; i++) {
        let elem = this.fadeInElements[i]
        elem.style.transition = 'all 1s'
        if (isElementNotInViewport(elem)) {
          elem.style.opacity = '1'
          elem.style.transform = 'translateY(0)'
        } else {
          elem.style.opacity = '0'
          elem.style.transform = 'translateY(200px)'
        }
      }
    },
    openPage(url) {
      window.open(url)
    },
    infoClick(index) {
      this.currentInfo = index
    }
  }
}
</script>

<style lang="less" scoped>
@import url('~@/styles/m.less');
/deep/ * {
  transition: all .3s;
}
.banner {
  width: 100vw;
  height: 29.17vw;
  min-height: 350px;
  // width: 1920px;
  // margin: 0 auto;
  .banner-item {
    width: 100vw;
    min-width: 1200px;
    height: 29.17vw;
    min-height: 350px;
    position: relative;
    img,video {
      display: block;
      height: 29.17vw;
      width: 100vw;
      min-width: 1200px;
      min-height: 350px;
      margin: 0 auto;
      overflow: hidden;
    }
    h2 {
      font-size: 38px;
      color: #ffffff;
      line-height: 1.5;
      font-weight: bold;
      position: absolute;
      z-index: 1;
      top: 184px;
      left: 363px;
    }
    h3 {
      font-size: 24px;
      color: #ffffff;
	    font-weight: lighter;
      line-height: 1.5;
      position: absolute;
      z-index: 1;
      top: 250px;
      left: 363px;
    }
    .button {
      padding: 0 35px;
      height: 48px;
      background-color: #044e9f;
      border-radius: 24px;
      color: #ffffff;
      line-height: 48px;
      font-size: 18px;
      text-align: center;
      position: absolute;
      z-index: 1;
      top: 350px;
      left: 363px;
      cursor: pointer;
    }
  }
}
.swiper1 {
  /deep/ .swiper-pagination-bullets {
    position: absolute !important;
    bottom: 4vw !important;
  }
  /deep/ .swiper-pagination-bullet-custom {
    width: 36px !important;
    height: 3px !important;
    background-color: #d8deea !important;
    opacity: 0.4 !important;
    border-radius: 0 !important;

    &:hover {
      opacity: 1;
    }

    &.swiper-pagination-bullet-active {
      width: 48px !important;
      opacity: 1 !important;
      background-color: #d8deea !important;
    }
  }
}
.swiper2 {
  /deep/ .swiper-pagination-bullet-custom {
    width: 36px !important;
    height: 3px !important;
    background-color: #d8deea !important;
    opacity: 1 !important;
    border-radius: 0 !important;

    &:hover {
      opacity: 1;
    }

    &.swiper-pagination-bullet-active {
      width: 48px !important;
      opacity: 1 !important;
      background-color: #044e9f !important;
    }
  }
}
.content { 
  width: 100%;
  padding: 20px 0;
  overflow: hidden;
  .title {
    width: 100%;
    height: 75px;
    text-align: center;
    position: relative;
    margin: 20px auto;
    h2 {
      width: 100%;
      position: absolute;
      top: 0;
      font-size: 40px;
      color: #333333;
      font-weight: bold;
      z-index: 2;
    }
    h3 {
      width: 100%;
      position: absolute;
      top: 28px;
      font-family: Impact;
	    font-size: 34px;
      color: #e6e8ec;
      z-index: 1;
    }
  }
  &.prod {
    background: url('~@/assets/homepage/prod-bg.png') right center no-repeat;
    .con-prod {
      width: 1200px;
      margin: 30px auto;
      margin-bottom: 60px;
      height: 424px;
      .prod-item {
        width: 254px;
        height: 424px;
        overflow: hidden;
        float: left;
        position: relative;
        margin-right: 4px;
        &:last-child {
          margin-right: 0;
        }
        .nimg {
          display: block;
          width: 254px;
          height: 424px;
          opacity: 1;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -2;
        }
        .himg {
          display: block;
          width: 426px;
          height: 424px;
          // opacity: 1;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -3;
          // display: none;
        }
        .text {
          width: 200px;
          margin: 36px auto;
          h3 {
            font-size: 24px;
            line-height: 1.5;
            margin: 0;
            // font-weight: lighter;
            color: #fff;
          }
          h4 {
            font-size: 24px;
            line-height: 1.5;
            margin: 0;
            // font-weight: lighter;
            color: #fff;
          }
          .no {
            font-size: 24px;
            font-weight: bold;
            color: #fff;
          }
          .tip {
            display: none;
            width: 16px;
            height: 4px;
            background-color: #ffffff;
            border-radius: 2px;
            margin: 10px 0;
          }
          .body {
            width: 318px;
            display: none;
            font-size: 16px;
            line-height: 30px;
            color: #ffffff;
          }
          .arrow {
            display: none;
            cursor: pointer;
            font-size: 48px;
            color: #ffffff;
            margin-left: -6px;
          }
        }
        &.act {
          width: 426px;
          .nimg {
            opacity: 0;
            left: 86px;
            // display: none;
          }
          .himg {
            // opacity: 1;
            // width: 426px;
            z-index: -1;
            // position: absolute;
          }
          .text {
            width: 318px;
            h3 {
              font-size: 26px;
              font-weight: bold;
            }
            h4 {
              font-size: 26px;
              font-weight: bold;
            }
            .no {
              display: none;
            }
            .tip {
              display: block;
            }
            .body {
              display: block;
            }
            .arrow {
              display: block;
            }
          }
        }
      }
    }
  }
  &.scene {
    width: 100vw;
    max-width: 1920px;
    margin: 0 auto;
	  // background: url('~@/assets/homepage/1208-3.mp4') center no-repeat;
    background-size: 100%;
    height: 45.8vw;
    max-height: 880px;
    .title {
      h2 {
        color: #ffffff;
      }
      h3 {
        color: #a5bbdb;
      }
    }
    .con-scene {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      video {
        display: block;
        width: 100%;
        height: 100%;
        // min-width: 1200px;
        // min-height: 350px;
        margin: 0 auto;
        overflow: hidden;
      }
    }
  }
  &.solution {
    background: url('~@/assets/homepage/solution-bg.png') left center #f3f6fa no-repeat;
    height: 720px;
    overflow: hidden;
    .con-solution {
      background: url('~@/assets/homepage/solutionitem-bg.png') center no-repeat;
      width: 1200px;
      height: 550px;
      margin: 0 auto;
      position: relative;
      margin-top: 40px;
      .solution-item {
        img {
          width: 560px;
          height: 520px;
          float: left;
          margin-right: 100px;
          margin-left: 25px;
        }
        h2 {
          margin-top: 56px;
          font-size: 26px;
          font-weight: bold;
          color: #333333;
          width: 430px;
          float: left;
        }
        .bar {
          background: url('~@/assets/homepage/solution-title.png') left center no-repeat;
          float: left;
          width: 176px;
          height: 10px;
        }
        p {
          margin-top: 20px;
          font-size: 16px;
          line-height: 30px;
          max-width: 430px;
          float: left;
          color: #555555;
        }
        .tips {
          width: 430px;
          float: left;
          margin-top: 25px;
          .tip {
            padding: 0 16px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            font-size: 16px;
            color: #888888;
            background-color: #ffffff;
            min-width: 88px;
            border-radius: 16px;
            float: left;
            margin-right: 14px;
          }
        }
      }
    }
  }
  &.info {
    background: url('~@/assets/homepage/info-bg.jpg') center #ffffff no-repeat;
    height: 681px;
    overflow: hidden;
    .title {
      h2 {
        color: #ffffff;
      }
      h3 {
        color: #2f547b;
      }
    }
    .button {
      width: 140px;
      height: 36px;
      background-color: #044e9f;
      border-radius: 18px;
      text-align: center;
      line-height: 36px;
      font-size: 16px;
      color: #ffffff;
      margin: 40px auto;
      cursor: pointer;
    }
    .con-info {
      width: 1200px;
      height: 400px;
      margin: 50px auto;
      margin-bottom: 0;
      overflow: hidden;
      .info-left {
        width: 600px;
        float: left;
        position: relative;
        img {
          width: 600px;
          height: 216px;
          margin-bottom: 10px;
        }
        .date {
          width: 96px;
          height: 88px;
          background-color: #044e9f;
          text-align: center;
          color: #ffffff;
          position: absolute;
          top: 0;
          left: 0;
          .day {
            font-size: 60px;
            line-height: 64px
          }
          .mon {
            font-size: 16px;
            line-height: 22px;
          }
        }
        h3 {
          font-size: 22px;
          line-height: 44px;
          font-weight: bold;
          color: #ffffff;
          width: 100%;
          height: 44px;
          overflow:hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        p {
          font-size: 16px;
          line-height: 24px;
          color: #cde6ff;
          height: 96px;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4;
          overflow: hidden;
        }
        .button {
          margin-top: 30px;
          font-size: 16px;
          background-color: #044e9f;
          line-height: 36px;
          color: #ffffff;
          padding: 0 28px;
          height: 36px;
          width: 140px;
          border-radius: 18px;
          cursor: pointer;
          i {
            float: right;
          }
        }
      }
      .info-right {
        width: 540px;
        float: right;
        .info-item {
          width: 560px;
          overflow: hidden;
          cursor: pointer;
          margin-top: 20px;
          margin-bottom: 30px;
          padding: 10px;
          padding-top: 0;
          &:hover {
            background: rgba(4, 78, 159, .4);
          }
          .date {
            width: 66px;
            float: left;
            color: #ffffff;
            text-align: center;
            .day {
              font-size: 60px;
              line-height: 64px
            }
            .mon {
              font-size: 16px;
              line-height: 22px;
            }
          }
          h3 {
            font-size: 20px;
            line-height: 36px;
            height: 36px;
            overflow:hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 460px;
            float: right;
            color: #ffffff;
          }
          p {
            font-size: 16px;
            color: #cde6ff;
            float: right;
            width: 460px;
            line-height: 20px;
            height: 40px;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
        }
      }
    }
  }
  &.news {
    height: 624px;
    margin: 0 auto;
    // position: relative;
    overflow: hidden;
    background: url('~@/assets/homepage/news-bg.png') center 142px #ffffff no-repeat;
    .button {
      width: 140px;
      height: 36px;
      line-height: 36px;
      color: #044e9f;
      font-size: 16px;
      border-radius: 18px;
      border: solid 1px #044e9f;
      margin: 20px auto;
      cursor: pointer;
      text-align: center;
    }
    .con-news {
      width: 1200px;
      margin: 0 auto;
      margin-top: 35px;
      position: relative;
      z-index: 1;
      .bar {
        width: 1200px;
        height: 2px;
        background-color: #e6e6e6;
        position: relative;
        top: 131px;  
        left: 0px;
        z-index: -1;
        // margin: 0 auto;
      }
      .lbar {
        width: 160px;
        height: 2px;
        background: linear-gradient(to right,#ffffff,#e6e6e6);
        position: absolute;
        top: 131px;  
        left: -160px;
        z-index: -1;
        // margin: 0 auto;
      }
      .rbar {
        width: 160px;
        height: 2px;
        background: linear-gradient(to left,#ffffff,#e6e6e6);
        position: absolute;
        top: 131px;  
        right: -160px;
        z-index: -1;
        // margin: 0 auto;
      }
      .news {
        overflow: hidden;
        .news-item {
          padding: 25px;
          width: 280px;
          height: 340px;
          margin: 20px 10px;
          overflow: hidden;
          float: left;
          cursor: default;
          .date {
            font-family: Impact;
	          font-size: 30px;
            color: #cccccc;
            margin: 19px 0;
          }
          .title-bar {
            width: 20px;
            height: 4px;
            background-color: #044e9f;
            border-radius: 2px;
          }
          h3 {
            font-size: 20px;
            line-height: 30px;
            color: #333333;
            font-weight: bold;
            margin-top: 20px;
            height: 60px;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
          p {
            font-size: 16px;
            color: #666666;
            line-height: 26px;
            height: 104px;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
            overflow: hidden;
          }
          &.act {
            background-color: #ffffff;
            box-shadow:0px 3px 3px #c8c8c8;
            .date {
              color: #044e9f;
            }
          }
        }
      }
    }
  }
}
</style>