<template>
   <div class="point-wrap">
      <div 
        v-for="(item) in pointArr" 
        :key="item.id"
        class="point" 
        :style="`left: ${item.left}; top: ${item.top};`" 
        @mouseenter.stop="handleEnter(item)"
        @mouseleave.stop="handleLeave"
      ></div>
      <!-- 信息展示弹窗 -->
      <transition name="info">
        <div 
          class="info-box" 
          v-show="isShowInfo" 
          :style="`left: ${currentItem.left}; top: ${currentItem.top};`" 
          @mouseenter.stop="handleEnter(currentItem)"
          @mouseleave.stop="handleLeave"
        >
          <div class="info" :class="infoClass">
            <p class="title">{{ currentItem.title }}</p>
            <hr>
            <p class="content">{{ currentItem.content }}</p>
            <button class="btn" @click="goPage">查看详情<a-icon type="right" /></button>
          </div>
        </div>
      </transition>
    </div>
</template>

<script>
export default {
  name: 'CustomPoint',
  data() {
    return {
      pointArr : [
        { id: 'APS', left: '30%', top: '62.5%', 
          title: 'APS高级生产计划排程软件', content: '系统以需求贯通销售、物流、制造、供应各环节，解决短期产能测算、生产计划、能力计划、物料计划编制及计划管理问题，为企业指标体系的建立及监控分析提供数据支撑。', 
          url: '/product?id=175' },
        { id: 'MES', 
          left: '45.5%', top: '42%', 
          title: '海行云智慧工厂MES平台', content: '系统以数字化工厂建设为目标，涵盖计划管理、生产执行、质量管理三大业务模块。产品形态上，搭建计划排程追踪平台、数字车间一体化作业平台、质量全流程移动作业平台等三大平台，指导生产决策，发挥数据能量。', 
          url: '/product?id=161' },
        { id: 'IOT', left: '62.5%', top: '39%', 
          title: '海行云物联平台', content: '通过构建物联平台，对生产制造过程数据进行实时采集处理，实现多场景的支撑与多方位数据应用。', 
          url: '/product?id=176' },
        { id: 'LES', left: '62%', top: '72.5%', 
          title: '海行云物流执行系统', content: '系统以物料拉动为核心，支持看板、内排、外排、ANDON等多种配送方式实现其从拉动、转运到上线的管理系统。', 
          url: '/product?id=168'  },
        { id: '零部件MES', left: '73%', top: '44%', 
          title: '海行云零部件MES平台', content: '助力零部件制造企业生产管理数字化、精细化，降本增效。', 
          url: '/product?id=169' },
        { id: '零部件WMS', left: '87%', top: '50%', 
          title: '海行云零部件WMS平台', content: '助力零部件制造企业物流仓储管理数字化、精细化，降本增效。', 
          url: '/product?id=170' },
        { id: '智慧园区', left: '71%', top: '78%', 
          title: '智慧园区管理解决方案', content: '应用新技术赋能园区，实现数据互通，业务互联，场景互动；塑立国民汽车工业制造智慧园区对外形象。', 
          url: '/program?id=223' },
        // {id: 'TMS', left: '26.5%', top: '85.5%', title: '', content: '', url: '' },
        // {id: 'EAM', left: '43%', top: '73%' },
        // {id: 'WMS', left: '72%', top: '64%', title: '', content: '', url: '' },
      ],
      isShowInfo: false,
      currentItem: {},   
      infoClass: '', // info动画
      timer: null,
    }
  },
  methods: {
    handleEnter(item) {
      clearTimeout(this.timer)
      this.isShowInfo = true
      this.currentItem = item
      this.infoClass = 'enter'
    },
    // 鼠标移动
    handleLeave() {
      this.timer = setTimeout(() => {
        this.isShowInfo = false
        this.infoClass = 'leave'
      }, 500)
    },
    // 页面跳转
    goPage() {
      this.$router.push({
        path: this.currentItem.url
      })
      scrollToTop()
    }
  },
  destroyed() {
    clearTimeout(this.timer)
  }
}
</script>

<style lang="less" scoped>
@mainColor: #214d92;
/* 大规模定制，相关点位样式控制 */
.point-wrap{
  position: absolute;
  z-index: 9;
  /* 和容器背景图片属性保持一致 */ 
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  .point{
    position: absolute;
    width: 1.85%;
    height: 4%;
    background: url('~@/assets/homepage/scene-point.png') no-repeat center center /100% 100%;
    cursor: pointer;
  }
  .info-box{
    transform: translate(-50%, -90%);
    position: absolute;
    z-index: 10;
    padding-bottom: 30px;
    .info{
      padding: 20px 24px;
      border-radius: 6px;
      background-image: linear-gradient(0deg, 
      rgba(241, 246, 254, 0.9) 0%, 
      rgba(247, 249, 254, 0.9) 100%);
      box-shadow: 0px 0px 24px 0px #62728e;
      border: solid 1px #ffffff;
      width: 360px;
    .title{
      color: @mainColor;
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 0;
    }
    hr{ width: 80px;height: 2px; background-color: @mainColor; border: none; margin: 10px 0;}
    .content{
      color: #262626;
      font-size: 14px;
      line-height: 26px;
    }
    .btn{ 
      width: 100%;
      display: block; 
      border: none;
      border-radius: 2px;
      padding:8px 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      color: @mainColor;
      background-color: #fff;
      &,/deep/.anticon,/deep/svg,/deep/path{
        transition: none !important;
      }
      &:hover{
        background-color: @mainColor;
        color: #fff;
      }
    }
  }
  }
 }
</style>
<style lang="less" scoped>
/* info添加动画 */ 
.info{
  &.enter{
  .content{ animation: content-in .5s ease forwards;}
  .btn{ animation: content-in .5s ease forwards;}
  }
  &.leave{
    .content{ animation: content-out .5s ease forwards;}
    .btn{ animation: content-out .5s ease forwards;}
  }
}

 /* 弹窗过渡动画 */ 
.info-enter-active{
  animation: info-fade-in 0.4s;
}
.info-leave-active{
  animation: info-fade-in 0.4s reverse;
}

@keyframes info-fade-in {
  0% {
    transform: translate(-50%, -100%);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -90%);
    opacity: 1;
  }
}

@keyframes info-infinite{
  0%{
    transform: translateY(0px)
  }
  50%{
    transform: translateY(-8px);
  }
  100%{
    transform: translateY(0);
  }
}

@keyframes content-in {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes content-out {
  0% {
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    transform: translateY(-20px);
    opacity: 0;
  }
}
</style>
